//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "VoyageInfo",
  data() {
    return {
      detailList: []
    };
  },
  props: {
      policyType: {
          type: String,
          default: '',
      },
    detail: {
        type: [Object, Array],
        default:()=>[],
    }
  },
  methods: {
      toggleRowSelection(row, selected) {
          console.log(row, selected);
          selected = true;
      },
    handleSelectionChange(val) {
      this.$emit("getSegment", val);
    },
    checkboxInit(row) {
      if (row.refundEnable === 2) {
        return false;
      } else {
        return true
      }

    }
  },
  mounted() {
    this.detail instanceof Array
      ? (this.detailList = this.detail)
      : (this.detailList = [this.detail]);
  },
  created() {},
  activated() {
    this.$refs.multipleTable.clearSelection();
  },
  watch: {},
  computed: {}
};
